import React from "react"
import { Link } from 'gatsby';
import { Container, Button } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"

const PhilosophyPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Get Started" />
    <h1 style={styles.header}>Investment Philosophy and Strategy</h1>
    <div style={styles.body}>
      <Container fluid={true}>
        <h3 style={{...styles.italic, ...styles.primaryText}}>Looking for Smoother Sailing to Your Dreams?</h3>
        <p>
          When it comes to investment management, a philosophy is only as good as the strategy that puts it into action.
        </p>
        <p>
          <span style={{fontWeight: 700}}>At the Coastal Advisory Group our investment philosophy is simple: Strive for Absolute Returns.</span>
          <br/>
          To achieve absolute returns, we invest differently and with different expectations. Typically, a classically 
          trained advisor uses computer software to set your portfolio on a formulaic asset allocation strategy and 
          they’re happy to outperform a benchmark. They then head off to play golf. It’s a nice life if you can get it. 
          But who’s guiding your investments? And what happens when the wind of our global economy changes direction?
        </p>
      </Container>
      </div>
        <div className="beach-couple-background">
          <div className="img--inner-content">
            <h5>Protecting and managing wealth starts with a good investment strategy</h5>
            <Link to="/contact">
              <Button className="main-btn">Learn more about ours</Button>
            </Link>
          </div>
        </div>
      <div style={styles.body}>
      <Container fluid={true}>
        <p>
          <span style={{fontWeight: 700}}>Our Strategy is to trim the sails and poise your portfolio for change.</span>
          <br/>
          To try to achieve absolute returns we strive to bring you positive returns on your investments. We begin with a 
          macro approach to find sectors that the market is rewarding and identify the strongest opportunities within it. 
          We then select investments within the sectors that we believe can make the biggest difference to your wealth.
        </p>
        <span style={{fontWeight: 700}}>As our client you receive:</span>
        <ul>
          <li>
            <span style={{fontWeight: 600}}>Investments that reflect who you are </span>as an individual and an investor. 
            As an independent advisor we’re not limited to offering financial products from a particular investment firm. 
            We can and do select what we believe to be the best investment — from mutual funds to ETFs to bonds to 
            structured notes to individual stocks — for your best interests.
          </li>
          <li>
            <span style={{fontWeight: 600}}>Protective Money Management</span> that includes continuous monitoring of the four elements that in our experience drive the most gains:
            <ol>
              <li>Global economies and markets as a whole</li>
              <li>The interaction of those markets</li>
              <li>The sectors which are performing</li>
              <li>The individual investment itself</li>
            </ol>
              Should we discover a change in any of these areas, we act prudently and efficiently to make adjustments to 
              try to protect and grow your portfolio, lower your risk to failing asset classes and lock in gains, while 
              seizing tax advantages.
          </li>
          <li>
            <span style={{fontWeight: 600}}>Dynamic asset relocation, </span>
            which simply means we continuously monitor your investments against the market and change your investments 
            and asset allocation as needed, sometimes daily. And equally important, our asset selection is geared to help 
            you meet your goals — not drive fees for us
          </li>
        </ul>
        <a className="primary-text" href="/contact">The hallmark of our investment strategy? “Money Goes Where It Is Best Treated.”</a>
      </Container>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38 !important'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default PhilosophyPage
